import React, { useState, useEffect} from 'react'
import { Paper, Table, TableHead, List, TableContainer, TableRow, TableCell, TableBody, Card, Avatar, Typography, Box, Grid, CardActionArea, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import ZoomVideo from '@zoom/videosdk'
import Vapi from '@vapi-ai/web'
import axios from 'axios'
import io from 'socket.io-client'
import CircularProgress from '@mui/material/CircularProgress'    
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize'
import { styled } from '@mui/system'

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  width: 400px;
  height: 200px; 
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 10px;
  color: white;
  padding: 10px;
  background: black;
  border: 0.5px solid white;
`,
);

const CodeEvalSelection = ({ showOnScreen, interviewStarted, interview }) => {
    const [selected, setSelected] = useState(-1)
    const [loading, setLoading] = useState(false)
    const [writtenExplanation, setWrittenExplanation] = useState('')
    const [howItsBetterValue, setHowItsBetterValue] = useState('')
    const [selectable, setSelectable] = useState(false)
    useEffect(() => {
      console.log('use effect', interviewStarted, interview.messages.length)
      if(interviewStarted && interview.messages.length >= 1 && !selectable) {
        setSelectable(true)
      }
    }, [selectable, interviewStarted, interview])

    let task = {
      context: ['', ''],
      snippets: ['', ''],
      how_its_better_options: []
    }
    if(interview.metadata !== undefined && interview.metadata.current_task_index !== undefined && interview.metadata.tasks !== undefined && interview.metadata.tasks[interview.metadata.current_task_index] !== undefined) {
      task = interview.metadata.tasks[interview.metadata.current_task_index]
    }    

    const renderSnippet = (text) => {
      return (
        <pre className="snippet" style={{ overflowX: 'auto', display: 'inline-block', wordBreak: 'break-word', paddingLeft: 10, paddingRight: 10, margin: 0, background: '#4451f6', color: 'white', height: '100%', width: '100%', fontSize: 16 }}>
          <code style={{ wordBreak: 'break-word', display: 'inline-block', overflowX: 'auto', paddingRight: 20 }}>
            {text}
          </code>
        </pre>                                            
      )
    }

    const renderContext = () => {
      if(interview.metadata.current_task_index < 0) {
        return <div></div>
      }

      return task.context.map((message, index) => {
        if(index !== 1) {
          return (
            <div key={index} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
              <Avatar sx={{ bgcolor: 'black', color: 'white', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
              <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', }}>{message}</Typography>
            </div>
          )
        }
      })
    }

    const handleConfirmSelection = () => {
        setLoading(true)
        // send option selection to server and 
        axios.post(`${proxyEndpoint}/code_eval_submission`, {
            callId: callId,
            selection: selected,
        })
        .then((response) => {
            console.log('Code eval submission success:', response.data)
            setLoading(false)
        })
        .catch((error) => {
            console.error('Code eval submission error:', error);
            setLoading(false)
        });        
    }

    const handleSubmitWrittenExplanation = () => {
      setLoading(true)
      axios.post(`${proxyEndpoint}/code-eval/written_explanation_submission`, {
        callId: callId,
        submission: writtenExplanation,
        selected_how_its_better_answer: howItsBetterValue
      })
      .then((response) => {
          console.log('code-eval/written_explanation_submission success:', response.data)
          setLoading(false)
          setWrittenExplanation('')
          setHowItsBetterValue('')
      })
      .catch((error) => {
          console.error('code-eval/written_explanation_submission error:', error);
          setLoading(false)
      });              
    }

    return (
        <Box sx={{ flexGrow: 1, padding: '25px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} style={{ textAlign: 'left', margin: 'auto' }}>
                  <p style={{ fontFamily: 'Mulish'}}>
                    {task.context[1]}
                  </p>
                </Grid>
                <Grid item xs={3} md={2}>
                  <div id="instructions" style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ fontFamily: 'Mulish', fontSize: 18 }}>Instructions</p>              
                  </div>               
                  <br/>    
                  {renderContext()}                                            
                </Grid>
                <Grid item xs={6} md={4} style={{ marginRight: 25 }}>
                    <h3>Option 1</h3>
                    <Card 
                      onClick={() => {
                        if(selectable) {
                          setSelected(0)
                        }
                      }
                    } style={{ border: selected === 0 ? '3px solid white' : '0.5px solid white', borderRadius: 4, height: 600, padding: 0  }}>
                        <CardActionArea style={{ height: '100%', padding: 0 }}>
                          {renderSnippet(task.snippets[0])}
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={4} style={{ marginRight: 25 }}>
                    <h3>Option 2</h3>
                    <Card onClick={() => {
                        if(selectable) {
                          setSelected(1)
                        }                      
                      }
                    } style={{ border: selected === 1 ? '3px solid white' : '0.5px solid white', borderRadius: 4, height: 600, padding: 0  }}>
                      <CardActionArea style={{ height: '100%', padding: 0, wordWrap: 'break-word' }}>
                          {renderSnippet(task.snippets[1])}
                      </CardActionArea>
                    </Card>
                </Grid>    
                <Grid item xs={12} md={12} style={{ textAlign: 'center', marginTop: 25 }}>
                    {(!interview['metadata']['verbal_explanation_mode'] && !interview['metadata']['written_explanation_mode'])  &&
                        <Button variant={'contained'} disabled={selected === -1} 
                        style={{
                            background: (selected === -1) ? 'gray': '#4451f6',
                            fontSize: 16,
                            color: 'white',
                            borderRadius: 25,
                            fontFamily: 'Mulish',
                        }}
                        onClick={handleConfirmSelection}>
                            {loading ? <CircularProgress /> : 'Confirm Selection'}
                        </Button>                    
                    }
                    {interview['metadata']['verbal_explanation_mode'] &&
                      <>
                        <h3>Answer the follow-up question out loud.</h3>
                        <p>{showOnScreen}</p>
                      </>
                    }
                    {interview['metadata']['written_explanation_mode'] &&
                      <>
                        <FormControl style={{
                          color: 'white'
                        }}>
                          <h3 style={{ color: 'white' }}>Please Indicate the Dimension Along Which One Response is Better</h3>
                          <RadioGroup
                            value={howItsBetterValue}
                            onChange={(e) => {setHowItsBetterValue(e.target.value)}}
                          >
                            {task['how_its_better_options'].map((option) => {
                              return (
                                <FormControlLabel value={option.value} control={<Radio style={{ color: 'white' }} />} label={option.label} />
                              )
                            })}
                          </RadioGroup>
                        </FormControl>    
                        <br />                    
                        <h3>Why did you pick Option {String(selected + 1)}? Write your explanation below and click "Submit".</h3>
                        <Textarea
                          placeholder="Your explanation..."
                          minRows={6}
                          value={writtenExplanation}
                          onChange={(e) => {setWrittenExplanation(e.target.value)}}
                        />
                        <br />
                        <br />
                        <Button variant={'contained'} disabled={selected === -1} 
                          style={{
                              background: (selected === -1) ? 'gray': '#4451f6',
                              fontSize: 16,
                              color: 'white',
                              borderRadius: 25,
                              fontFamily: 'Mulish',
                          }}
                          onClick={handleSubmitWrittenExplanation}>
                              {loading ? <CircularProgress /> : 'Submit'}
                          </Button>                                            
                      </>
                    }
                </Grid>                
            </Grid>
        </Box>
    )
}

const ScorecardModal = ({ open, setOpen, interview }) => {
  return (
    (
      <Dialog
        style={{
          background: 'black',
          color: 'white',
          border: '0.5px solid #5F6368',
          fontFamily: 'Mulish'                  
        }}                          
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="scorecard-dialog-title"
        aria-describedby="scorecard-dialog-description"
        fullWidth
        maxWidth="md" // Changed from default to make the dialog wider
      >
        <DialogTitle id="scorecard-dialog-title" 
          style={{
            background: 'black',
            color: 'white',
            border: '0.5px solid #5F6368',
            fontFamily: 'Mulish'                  
          }}                            
        >Interview Scorecard</DialogTitle>
        <DialogContent
          style={{
            background: 'black',
            color: 'white',
            border: '0.5px solid #5F6368',
            fontFamily: 'Mulish'                  
          }}              
        >
          <DialogContentText id="scorecard-dialog-description">
            Here's how you did in your interview.
          </DialogContentText>
          <List>
            <TableContainer component={Paper} 
              style={{
                background: 'black',
                color: 'white',
                border: '0.5px solid #5F6368',
                fontFamily: 'Mulish'                  
              }}                                
            >
              <Table aria-label="scorecard table">
                <TableHead style={{
                  background: 'black',
                  color: 'white',
                  border: '0.5px solid #5F6368',
                  fontFamily: 'Mulish'                               
                }}>
                  <TableRow style={{
                    background: 'black',
                    color: 'white',
                    border: '0.5px solid #5F6368',
                    fontFamily: 'Mulish'                                 
                  }}>
                    <TableCell style={{ color: 'white', fontFamily: 'Mulish'}}>Criteria</TableCell>
                    <TableCell style={{ color: 'white', fontFamily: 'Mulish'}} align="left">Score</TableCell>
                    <TableCell style={{ color: 'white', fontFamily: 'Mulish'}} align="left">Feedback</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{
                  background: 'black',
                  color: 'white',
                  border: '0.5px solid #5F6368',
                  fontFamily: 'Mulish'                               
                }}>
                  {interview.scorecard && interview.scorecard.length === 0 &&
                  <TableRow style={{
                    background: 'black',
                    color: 'white',
                    border: '0.5px solid #5F6368',
                    fontFamily: 'Mulish'                        
                  }}>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress style={{ color: 'white' }} />
                    </TableCell>
                  </TableRow>
                  }
                  {interview.scorecard && interview.scorecard.length > 0 &&
                    <>
                      {interview.scorecard.map((row) => (
                        <TableRow key={row.signal} style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish'                                       
                        }}>
                          <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} component="th" scope="row">
                            {row.signal}
                          </TableCell>
                          <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} align="left">{row.score}</TableCell>                              
                          <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} align="left">{row.feedback}</TableCell>
                        </TableRow>
                      ))}                        
                    </>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </List>   
        </DialogContent>
      </Dialog>
    )
  )  
}

const GetStartedDialog = ({ interviewStarted, inputsEnabled, enableInputs, startInterview }) => {
    return (
        <Dialog open={!interviewStarted}>
        <DialogTitle style={{
          background: 'black',
          color: 'white',
          border: '0.5px solid #5F6368',
          fontFamily: 'Mulish'
        }}>Get Started</DialogTitle>
        <DialogContent style={{
          background: 'black',
          color: 'white',
          border: '0.5px solid #5F6368',
          padding: 10
        }}>
          <DialogContentText>
              {!inputsEnabled &&
                  <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
                  You will be doing a pre-screening labeling task interview. Please enable your microphone and camera to talk to Steve, your AI interviewer.
                  </div>
              } 
              {inputsEnabled &&
                  <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
                  Your microphone and camera is enabled! Press the start interview button and our interviewer Steve will read you off a prompt and instructions.
                  </div>                            
              }           
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ background: 'black', border: '0.5px solid #5F6368', padding: 10 }}>
          {!inputsEnabled &&
            <Button autoFocus onClick={enableInputs} style={{ color: 'white', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}>
              {inputsEnabled ? 'Mic and Camera Enabled' : 'Enable Mic and Camera'}
            </Button>                        
          }
          {inputsEnabled &&
            <Button onClick={() => {startInterview()}} disabled={!inputsEnabled} style={{ color: 'white', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}>
              Start Interview
            </Button>                        
          }
        </DialogActions>
      </Dialog>                
    )
}

var client = ZoomVideo.createClient()
var stream
var stopInvoked = false

const vapi = new Vapi('c6a21d89-0ffd-45b2-9ad4-28a1fd2708f8')
var callId;
var socket;

var proxyEndpoint = 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var wsEndpoint = 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var vapiEnvId = '3e10d61c-25cb-4da0-9f4c-f721d4fdf8ae' // prod
var env = String(process.env.REACT_APP_ENV)

if(env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
  wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
  vapiEnvId = '02cb35d0-dd7d-47d1-893f-8367b96aa265'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5000'
  wsEndpoint = 'ws://localhost:5000'
  vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'
}

const emptyInterviewObject = {
    state: 'none',
    messages: [],
    context: [],
    mute: false,
    metadata: {
      email_case_submissions: -1,
      current_task_index: 0,
      tasks: [
        {snippets: ['', ''], context: []}        
      ],
      verbal_explanation_mode: false, 
      written_explanation_mode: false,      
    },
    scorecard: [],
    stage: ''
}

const CodeEval = () => {
    const [interviewStarted, setInterviewStarted] = useState(false)
    const [inputsEnabled, setInputsEnabled] = useState(false)
    const [visualState, setVisualState] = useState('')
    const [scorecardModalOpen, setScorecardModalOpen] = useState(false)
    const [interview, setInterview] = useState(emptyInterviewObject)
    const [showOnScreen, setShowOnScreen] = useState('')

    const startInterview = () => {
        console.log('startInterview')
        setInterviewStarted(true);
        vapi.start(vapiEnvId)
        .then((data) => {
          // i assume ID is callId
          callId = data['id']
          if(socket === undefined) {
              socket = initWebsocketConnection(callId);
  
              // get JWT from server
              fetch(proxyEndpoint + '/get_video_jwt', {
                body: JSON.stringify({
                  callId: callId
                }), 
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then((data) => {
                data.json().then((jsonData) => {
                  console.log('jwt json res', jsonData)
  
                  client.init('en-US', 'Global', { patchJsMedia: true }).then(() => {
                    client
                      .join(callId, jsonData.jwt, 'candidate')
                      .then(() => {
                        stream = client.getMediaStream()
                        stream.startVideo()
                        const recordingClient = client.getRecordingClient()
                        recordingClient.startCloudRecording()
                        .then(() => {
                          console.log('recording')
                        })
                        .catch((err) => {
                          console.error(err)
                        })
                      })
                  })                                  
                })
              })
              .catch((err) => {
                console.error(err)
              })          
          }                  
        })
        .catch((err) => {console.error(err)})
  
        vapi.on('speech-start', () => {
            console.log('Speech has started');
            setVisualState('speaking');
        });
        
        vapi.on('speech-end', () => {
            console.log('Speech has ended');
            setVisualState('listening');
        });
        
        vapi.on('call-start', () => {
            console.log('Call has started');            
        });
        
        vapi.on('call-end', () => {
            console.log('Call has stopped');
        });
        
        vapi.on('volume-level', (volume) => {
        });
        
        // Function calls and transcripts will be sent via messages
        vapi.on('message', (message) => {
            console.log(message);
        });
        
        vapi.on('error', (e) => {
            console.error(e)
        });           
    }

    const enableInputs = () => {
        console.log('enableInputs')
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
        .then(function(stream) {
          setInputsEnabled(true);
        })
        .catch(function(err) {
          console.log('Microphone permission denied');
        });        
    }

    const handleStopInterview = () => {
        console.log('handleStopInterview')
        if(!scorecardModalOpen) {
            const recordingClient = client.getRecordingClient()
            recordingClient.stopCloudRecording()
            .then(() => {
            console.log('stopped')
            stream.stopVideo()
            })        
            .catch((err) => {
            console.error(err)
            })

            vapi.stop()
            // get scorecard
            if(stopInvoked) {
                return 
            }
            stopInvoked = true
    
            console.log('making request')
            axios.post(`${proxyEndpoint}/scorecard`, { callId: callId })
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {          
                console.error('Error fetching scorecard:', error);
            });

            setScorecardModalOpen(true);             
            setInterview(emptyInterviewObject)
        }   
    }    

    const initWebsocketConnection = (callId) => {  
        console.log('initWebsocketConnection')
        const socket = io(wsEndpoint, {
          withCredentials: true,
          transports: ["websocket"]        
        });
        socket.on('connect', () => {
          console.log('Websocket connected');
        });
        socket.on('done', (data) => {
          if(data.callId === callId) {
            setTimeout(() => {
              handleStopInterview()
            }, 10000)
          }
        })
  
        socket.on('disconnect', () => {
          console.log('Websocket disconnected');
        });
  
        socket.emit('register', {
          callId: callId,
          clientType: 'desktop',
          interviewType: 'swe_code_eval',
          customer: 'scale',
        });
        
        socket.on('update', (interview) => {
          console.log('update', interview.metadata.callId, callId)
          console.log(interview)
          if(interview.metadata.callId === callId) {
            setInterview(interview);
            if(interview.stage === 'end') {
              setTimeout(() => {
                console.log('Interview has ended. Redirecting to scorecard...');
                navigator.mediaDevices.getUserMedia({ audio: true, video: true })
                  .then(stream => {
                    stream.getTracks().forEach(track => track.stop());
                    console.log('User input turned off.');
                  })
                  .catch(error => console.error('Error turning off input:', error));
                handleStopInterview()
              }, 15000);
            }
          }
        });

        socket.on('show_on_screen', (res) => {
          if(res.callId === callId) {
            console.log('showing on screen')
            setShowOnScreen(res.res)
          }          
        })
  
        socket.on('parrot', (res) => {
          console.log('par', res.callId)
          if(res.callId === callId) {
            console.log('parroting')
            vapi.send({
              type: "add-message",
              message: {
                role: "system",
                content: "parrot_: " + res.res,
              },
            });        
          }
        })
  
        return socket;
      }    

    return (
        <div style={{ margin: 25 }}>
          <Grid item container xs={12}>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <div style={{fontSize: 24, fontFamily: 'Mulish'}}>
                Software Engineer Labeling Tasks Interview                                        
              </div>
            </Grid>   
            <Grid item xs={12}>
              <div style={{ marginTop: 25, display: 'flex', alignItems: 'center', marginRight: 30, fontSize: 16, fontFamily: 'Mulish', visibility: interviewStarted ? 'visible' : 'hidden', color: '#4451f6', marginBottom: 0, paddingBottom: 0 }}>
                {visualState === '' &&
                  <div style={{ color: interviewStarted ? 'white' : 'black' }}>
                    Steve is Connecting...
                  </div>
                }
                {visualState === 'listening' &&
                  <div>
                    Steve is <b>Listening</b>
                  </div>
                }
                {visualState === 'speaking' &&
                  <div>
                    Steve is <b>Speaking</b>
                  </div>
                }                          
              </div>              
            </Grid>
          </Grid>
            <GetStartedDialog 
              interviewStarted={interviewStarted}
              inputsEnabled={inputsEnabled}
              enableInputs={enableInputs}
              startInterview={startInterview}
            />
            <CodeEvalSelection 
              interviewStarted={interviewStarted}
              interview={interview}
              setInterview={setInterview}
              showOnScreen={showOnScreen}
            />    
            <ScorecardModal
              open={scorecardModalOpen}
              setOpen={setScorecardModalOpen}
              interview={interview}
            />        
        </div>
    )
}

export default CodeEval;