import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import CodeEval from './CodeEval';

import { createTheme, ThemeProvider } from '@mui/material';
import reportWebVitals from './reportWebVitals';
import * as Frigade from '@frigade/react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// Override Material UI Theme Font
const customTheme = createTheme({
  typography: {
    fontFamily: 'Figtree, sans-serif',
  },
});

// todo: change to interview session id when we get it
const userId = crypto.randomUUID()
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/code-eval",
    element: <CodeEval />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Frigade.Provider
      apiKey="api_public_pOjkno11aXrVaemPVSWOrfHQOrIAlZUfVhiUEQRn8XsqXXOhsihrO6ppqzL7MezC"
      userId={userId}
      organizationId='athena'
    >
      <ThemeProvider theme={customTheme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Frigade.Provider>    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
